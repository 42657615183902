import React from 'react';
import { Helmet } from "react-helmet";

const Article5 = () => {
  return (
    <div>
      <h1>Titre de l'article 1</h1>
      <p>Contenu complet de l'article 1...</p>
    </div>
  );
};

export default Article5;