import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CookieConsentBanner from "./pages/CookieConsentBanner.min";
import Contact from "./pages/Contact.min";
import Home from "./pages/Home.min";
import Infogérance from "./pages/Infogérance.min";
import Cybersécurité from "./pages/Cybersécurité.min";
import Solutions from "./pages/Solutions.min";
import Recrutement from "./pages/Recrutement.min";
import Blog from "./pages/Blog.min";
import Article1 from './pages/articles/Article1.min';
import Article2 from './pages/articles/Article2.min';
import Article3 from './pages/articles/Article3.min';
/*import Article4 from './pages/articles/Article4.min';
import Article5 from './pages/articles/Article5.min';
import Article6 from './pages/articles/Article6.min';*/
import Maintenance from './pages/Maintenance.min';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  return (
    <Router>
      <CookieConsentBanner />  {/* Ajout du composant CookieConsentBanner */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Infogérance" element={<Infogérance />} />
        <Route path="/Cybersécurité" element={<Cybersécurité />} />
        <Route path="/Solutions" element={<Solutions />} />
        <Route path="/Recrutement" element={<Recrutement />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Blog/Article1" element={<Article1 />} />
        <Route path="/Blog/Article2" element={<Article2 />} />
        <Route path="/Blog/Article3" element={<Article3 />} />
    {/* <Route path="/Blog/Article4" element={<Article4 />} />
        <Route path="/Blog/Article5" element={<Article5 />} />
        <Route path="/Blog/Article6" element={<Article6 />} />  */}
        <Route path="/maintenance" element={<Maintenance />} />
      </Routes>
    </Router>
  );
}
/* Pour tout router vers la page de maintenance.
<Routes>
    <Route path="/maintenance" element={<Maintenance />} />
    <Route path="*" element={<Maintenance />} />
</Routes>
*/

export default App;
