import React from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Coordonnées = () => {
  return (
    <div className="coordonnees"> 
      <Helmet>
        <title>Coordonnées - Mitrocom</title>
        <meta name="description" content="Trouvez nos coordonnées, y compris l'adresse, le numéro de téléphone et l'email pour nous contacter chez Mitrocom." />
        <meta name="keywords" content="Mitrocom, coordonnées, contact, adresse, téléphone, email" />
      </Helmet>
      <h2>Nos coordonnées</h2>
      <div className="coordonnee-item">
        <FontAwesomeIcon icon={faMapPin} className="icon" />
        <p>Adresse : Bureau 326 - 59 rue de Ponthieu, 75008 Paris</p>
      </div>
      <div className="coordonnee-item">
        <FontAwesomeIcon icon={faPhone} className="icon" />
        <p>Téléphone : 06.58.41.13.68</p>
      </div>
      <div className="coordonnee-item">
        <FontAwesomeIcon icon={faEnvelope} className="icon" />
        <p>Email : contact@mitrocom.fr</p>
      </div>
    </div>
  );
};

export default Coordonnées;


